import React, {useContext} from 'react';
import "./ConfirmLogout.scss";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

function ConfirmLogout(props) {
	const {store} = useContext(Context);
	return (
		<div className="__logout">
			<p>Вы действително хотите выйти?</p>
			<div className="btn_group">
				<button onClick={() => props.setConfirmLogoutOpen(false)}>Нет</button>
				<button onClick={() => store.logout()}>Выйти</button>
			</div>
		</div>
	);
}

export default observer(ConfirmLogout);