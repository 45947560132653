import React from 'react';
import "./OurPartners.scss";
import aboutPartnerImg1 from "../../img/aboutSberbank.svg";
import aboutPartnerImg2 from "../../img/aboutOpenAI.svg";
import aboutPartnerImg3 from "../../img/aboutTesla.svg";
import aboutPartnerImg4 from "../../img/aboutApple.svg";

function OurPartners() {
	return (
		<div className="__partners_info">
			<div className="container">
				<h2>Среди наших партнеров</h2>
				<div className="images_wrapper">
					{/*<div className="__images">*/}
					{/*	<img src={aboutPartnerImg1} alt="img"/>*/}
					{/*	<img src={aboutPartnerImg2} alt="img"/>*/}
					{/*	<img src={aboutPartnerImg3} alt="img"/>*/}
					{/*	<img src={aboutPartnerImg1} alt="img"/>*/}
					{/*	<img src={aboutPartnerImg2} alt="img"/>*/}
					{/*	<img src={aboutPartnerImg3} alt="img"/>*/}
					{/*</div>*/}
					{/*<div className="__images">*/}
					{/*	<img src={aboutPartnerImg1} alt="img"/>*/}
					{/*	<img src={aboutPartnerImg2} alt="img"/>*/}
					{/*	<img src={aboutPartnerImg3} alt="img"/>*/}
					{/*	<img src={aboutPartnerImg1} alt="img"/>*/}
					{/*	<img src={aboutPartnerImg2} alt="img"/>*/}
					{/*	<img src={aboutPartnerImg3} alt="img"/>*/}
					{/*</div>*/}
					{/*<div className="__images">*/}
					{/*	<img src={aboutPartnerImg1} alt="img"/>*/}
					{/*	<img src={aboutPartnerImg2} alt="img"/>*/}
					{/*	<img src={aboutPartnerImg3} alt="img"/>*/}
					{/*	<img src={aboutPartnerImg1} alt="img"/>*/}
					{/*	<img src={aboutPartnerImg2} alt="img"/>*/}
					{/*	<img src={aboutPartnerImg3} alt="img"/>*/}
					{/*</div>*/}
				</div>
			</div>
		</div>
	);
}

export default OurPartners;