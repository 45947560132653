import React, {useEffect, useState} from 'react';
import "./Main.scss";
import Header from "../../components/Header/Header";
import OrderInfo from "../../components/OrderInfo/OrderInfo";
import Benefits from "../../components/Benefits/Benefits";
import ReviewList from "../../components/ReviewList/ReviewList";
import FAQ from "../../components/FAQ/FAQ";
import Legality from "../../components/Legality/Legality";
import PartnerRequest from "../../components/PartnerRequest/PartnerRequest";
import OurPartners from "../../components/OurPartners/OurPartners";
import ContactUs from "../../components/ContactUs/ContactUs";
import ReviewService from "../../services/ReviewService";

function Main() {
	const [reviews, setReviews] = useState([]);

	// useEffect(() => {
	// 	const fetchReviewsFromServer = async () => {
	// 		try {
	// 			// const response = await ReviewService.getReview();
	// 			// setReviews(response.data);
	// 		} catch (error) {
	// 			console.error('Error fetching reviews:', error);
	// 		}
	// 	};
	//
	// 	fetchReviewsFromServer().then(r => r);
	// }, []);
	return (
		<>
			<Header/>
			<OrderInfo/>
			<ContactUs/>
			<Benefits/>
			<Legality/>
			<PartnerRequest/>
			<OurPartners/>
			<ReviewList reviews={reviews}/>
			<FAQ/>
		</>
	);
}

export default Main;