import React, {useState, useContext} from 'react';
import "./Profile.scss";
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import ProfileSettings from "../ProfileSettings/ProfileSettings";
import ProfileMenu from "../../components/ProfileMenu/ProfileMenu";
import ProfileTransactions from "../ProfileTransactions/ProfileTransactions";
import ProfileAccount from "../ProfileAccount/ProfileAccount";
import ProfileReferral from "../ProfileReferral/ProfileReferral";


function Profile(props) {
	const {store} = useContext(Context);
	const [selectedButton, setSelectedButton] = useState('account');

	const renderComponent = () => {
		switch (selectedButton) {
			case 'account':
				return <ProfileAccount/>;
			case 'transactions':
				return <ProfileTransactions/>;
			case 'referral':
				return <ProfileReferral />;
			case 'settings':
				return <ProfileSettings />;
			default:
				return null;
		}
	};


	return (
		<div className="profile">
			<div className="container-top">
				<div className="__wrapper">
					<div className="__menu_container">
						<ProfileMenu setSelectedButton={setSelectedButton}/>
					</div>
					<div className="__content_container">{renderComponent()}</div>
				</div>
			</div>
		</div>
	);
}

export default observer(Profile);
