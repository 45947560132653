import React, {useContext, useEffect, useState} from "react";
import './App.css';
import {Route, Routes, useLocation} from "react-router-dom";
import {Context} from "./index";
import {observer} from "mobx-react-lite";
import Main from "./pages/Main/Main";
import Contacts from "./pages/Contacts/Contacts";
import Partners from "./pages/Partners/Partners";
import Navigation from "./components/Navigation/Navigation";
import About from "./pages/About/About";
import Footer from "./components/Footer/Footer";
import Privacy from "./pages/Privacy/Privacy";
import Rules from "./pages/Rules/Rules";
import Profile from "./pages/Profile/Profile";
import Login from "./pages/Login/Login";
import ReviewForm from "./components/ReviewForm/ReviewForm";
import Registration from "./pages/Registration/Registration";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import CookieConsent from "./components/CookieConsent/CookieConsent";



function App() {
    const {store} = useContext(Context);
    const location = useLocation();
    const [cookieAccepted, setCookieAccepted] = useState(localStorage.getItem("cookieAccepted") === "true");

    // useEffect(() => {
    //     if(localStorage.getItem("token")){
    //         store.checkAuth();
    //     }
    // }, [])

    const handleAcceptCookie = () => {
        setCookieAccepted(true);
        localStorage.setItem("cookieAccepted", "true");
    };

    if(store.isLoading){
        return <div></div>
    }

    const renderCondition = !location.pathname.includes("/login") &&
        !location.pathname.includes("/registration") && !location.pathname.includes("/reset")

    return (
        <div className="App">
            <div className={`${cookieAccepted ? '' : 'blur'}`}>
                <Navigation/>
                <Routes>
                    <Route path="/" element={<Main/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/contacts" element={<Contacts/>}/>
                    <Route path="/partners" element={<Partners/>}/>
                    <Route path="/reviewForm" element={<ReviewForm/>}/>
                    <Route path="/privacy" element={<Privacy/>}/>
                    <Route path="/rules" element={<Rules/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/registration" element={<Registration/>}/>
                    <Route path="/profile" element={<PrivateRoute/>}>
                        <Route path="" element={<Profile/>}/>
                    </Route>
                    <Route path="/reset" element={<ResetPassword/>}/>
                </Routes>
                {renderCondition && <Footer/>}
            </div>
            {!cookieAccepted && <CookieConsent onAccept={handleAcceptCookie} />}
        </div>
    );
}

export default observer(App);
