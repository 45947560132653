import React, {useState} from 'react';
import "./Contacts.scss";
import telegram from "../../img/contactsTelegram.svg";
import linkedIn from "../../img/contactsLinkedIn.svg";
import vk from "../../img/contactsVk.svg";
import MapPoint from "../../components/MapPoint/MapPoint";
import axios from "axios";

function Contacts() {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [info, setInfo] = useState("");
	const [isPrivacyPolicyAgreed, setIsPrivacyPolicyAgreed] = useState(false);


	const [isValidName, setIsValidName] = useState(true);
	const [isValidEmail, setIsValidEmail] = useState(true);
	const [isPrivacyPolicyValid, setIsPrivacyPolicyValid] = useState(true);

	const handleNameChange = (event) => {
		setName(event.target.value);
		setIsValidName(true)
	};
	const handleEmailChange = (event) => {
		setEmail(event.target.value);
		setIsValidEmail(true);
	};
	const handleInfoChange = (event) => {
		setInfo(event.target.value);
	}
	const handlePrivacyPolicyChange = (event) => {
		setIsPrivacyPolicyAgreed(event.target.checked);
		setIsPrivacyPolicyValid(true);
	};

	const handleSubmit = () => {
		const isValidName = name !== "";
		setIsValidName(isValidName);

		const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
		const isValidEmail = emailPattern.test(email);
		setIsValidEmail(isValidEmail);

		setIsPrivacyPolicyValid(isPrivacyPolicyAgreed);

		if(isValidName && isValidEmail && isPrivacyPolicyValid ){
			const data = {
				service_id: "service_zfl7wh6",
				template_id: "template_lg6z1dv",
				user_id: "hlz_O_IK2KQlPFddA",
				template_params: {
					'name': name,
					'email': email,
					'info': info
				}
			};

			axios.post('https://api.emailjs.com/api/v1.0/email/send', data)
				.then(response => {
					console.log("Successful sent");
				})
				.catch(error => {
					console.error('Oops... ' + error);
				});
			setName("");
			setEmail("");
			setInfo("");
			setIsPrivacyPolicyAgreed(false);
		}
	}

	return (
		<div className="contacts">
			<div className="contact-us">
				<h1>Свяжитесь с нами</h1>
				<div className="contact-info">
					<p>Наш телефон</p>
					<ul>
						<li><a href="tel:+79673671612">+7(967)367-16-12</a></li>
					</ul>
				</div>
				<div className="contact-info">
					<p>Наша почта</p>
					<ul>
						<li><a href="mailto:info@dexpay.ru">info@dexpay.ru</a></li>
					</ul>
				</div>
				<div className="contact-info">
					<p>Реквизиты</p>
					<ul>
						<li>ООО "ДИТ"</li>
						<li>ИНН 1660098058</li>
						<li>ОГРН 1071690007311</li>
						<li>Адрес: 420029, РТ, г. Казань, ул. Сибирский тракт, д. 32</li>
					</ul>
				</div>
				<div className="contact-info">
					<p>Соцсети</p>
					<div className="social-wrapper">
						<a href="#" target="_blank" rel="noopener"><img src={telegram} alt="#"/></a>
						<a href="#" target="_blank" rel="noopener"><img src={linkedIn} alt="#"/></a>
						<a href="#" target="_blank" rel="noopener"><img src={vk} alt="#"/></a>
					</div>
				</div>
				<MapPoint/>
			</div>
			<div className="contact-form">
				<div className="form">
					<div className="label-group">
						<label>Имя<span>*</span></label>
						<input type="text"
						       value={name}
						       onChange={handleNameChange}
						       className={!isValidName ? "invalid" : ""}
						/>
						{!isValidName && (
							<p className="error-message">Заполните поле</p>
						)}
					</div>
					<div className="label-group">
						<label>E-mail<span>*</span></label>
						<input type="text"
						       value={email}
						       onChange={handleEmailChange}
						       className={!isValidEmail ? "invalid" : ""}
						/>
						{!isValidEmail && (
							<p className="error-message">Заполните поле</p>
						)}
					</div>
					<div className="label-group">
						<label>Сообщение</label>
						<textarea value={info}
						          onChange={handleInfoChange}
						/>
					</div>
					<button onClick={handleSubmit}>Отправить</button>
					<div className="form-agree">
						<div className="agree-text">
							<input
								type="checkbox"
								id="privacyPolicyAgree"
								checked={isPrivacyPolicyAgreed}
								onChange={handlePrivacyPolicyChange}
							/>
							<label htmlFor="privacyPolicyAgree">
								Вы соглашаетесь с
								<a href="/privacy"> политикой конфиденцальности</a>
								<span>*</span>
							</label>
						</div>
						{!isPrivacyPolicyValid && (
							<p className="error-message">Примитие политику конфиденцальности</p>
						)}
					</div>
				</div>
			</div>
		</div>

	);
}

export default Contacts;