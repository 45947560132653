import React from 'react';
import "./Rules.scss";
import {useNavigate} from "react-router-dom";

function Rules() {
	const navigate = useNavigate();
	const linkTo = (isPrivacy) => {
		if(isPrivacy){
			navigate("/privacy");
			window.scrollTo(0, 0);
		}else{
			navigate("/contacts");
			window.scrollTo(0, 0);
		}
	}
	return (
		<div className="rules">
			<div className="container-top">
				<div className="container">
					<h1>Правила пользования</h1>
					<p>Перед тем как воспользоваться услугами сервиса Dexpay, Пользователь обязан ознакомиться в полном объеме с Правилами и условиями предоставления услуг сервисом Dexpay. Использование услуг сервиса Dexpay возможно только в случае, если Пользователь принимает все условия соглашения.</p>
					<ul className="__list">
						<li>Термины и определения</li>
						<ul className="__sublist">
							<li>1.1 Dexpay ("Сервис") – это сервис продажи цифровых активов (стейблкоинов USDT), оказывающий услуги Пользователям с помощью специального программного интерфейса, расположенный в сети Интернет по адресу Dexpay.</li>
							<li>1.2. Пользователь - это любое физическое лицо, согласившееся со всеми условиями, предложенными Сервисом, и присоединившееся к данному соглашению.</li>
							<li>1.3. Цифровой актив (стейблкоин USDT) - Это цифровые активы со стабильной стоимостью, привязанной, как правило, к доллару США. Стейблкоины, используют различные методы стабилизации своей стоимости. Централизованные стейблкоины обеспечены депозитами денежных средств и их эквивалентов в финансовых учреждениях, таких как банки.</li>
							<li>1.4. Платеж - перевод цифровых активов (стейблкоинов USDT) от плательщика к получателю.</li>
							<li>1.5. Заявка - информация, поданная Пользователем с использованием средств Сервиса в электронном виде, свидетельствующая о его намерениях воспользоваться услугами Сервиса на условиях, предложенных Сервисом и указанных в параметрах заявки.</li>
							<li>1.6. Пользователь и Dexpay - совместно именуются Стороны.</li>
							<li>1.7. Курс - стоимостное соотношение валюты при их обмене на цифровые активы (стейблкоины USDT) .</li>
							<li>1.8. Резервы электронных валют – суммы имеющихся в наличии у Сервиса Электронных валют или денежных средств для совершения услуг. Суммы резервов указаны на Интернет-сайте Сервиса на главной странице.</li>
							<li>1.9. Интернет-сайт Сервиса – Dexpay.</li>
						</ul>
						<li>Предмет соглашения и порядок вступления его в силу</li>
						<ul className="__sublist">
							<li>2.1. Данное соглашение регламентирует отношения между Пользователем и Сервисом по поводу услуг, предоставляемых Сервисом Пользователю и отменяет все предшествующие договоренности между Сервисом и Пользователем по данному предмету.</li>
							<li>2.2. Данное соглашение не отменяет действующего законодательства стран регистрации и местонахождения Сервиса и Пользователя, а также договорных отношений между Пользователем и Системой (системами) электронных расчетов.</li>
							<li>2.3. Данное соглашение считается принятым на условиях публичной оферты, акцептуемой Пользователем в ходе подачи им Заявки, являющейся неотъемлемой частью настоящего соглашения.
								<ul className="__sublist">
									<li>2.3.1. Публичной офертой признается отображаемая Сервисом информация о параметрах и условиях Заявки.</li>
									<li>2.3.2. Акцептом публичной оферты признается совершение Пользователем действий по завершению формирования Заявки, подтверждающих его намерение совершить сделку с Сервисом на условиях, предложенных Сервисом непосредственно перед завершением формирования Заявки. В случае регистрации на Интернет-сайте Сервиса настоящее соглашение вступает в действие в момент проставления галочки перед словами «Я согласен с условиями оферты» и нажатия кнопки «Зарегистрироваться».</li>
									<li>2.3.3. Дата и время акцепта, а также параметры условий Заявки фиксируются Сервисом автоматически в момент завершения формирования Заявки.</li>
								</ul>
							</li>

							<li>2.4. Данное соглашение вступает в действие с момента завершения формирования Заявки Пользователем.</li>
							<li>2.5. Данное соглашение прекращает свое действие с момента поступления на реквизиты, предоставленные Пользователем, Цифровых активов (стейлбкоинов USDT) в сумме, предусмотренной параметрами Заявки Пользователя, либо с момента аннулирования заявки.</li>
							<li>2.6. Стороны признают настоящее соглашение в электронной форме равнозначным по юридической силе договору, заключенному в письменной форме.</li>
							<li>2.7. Сервис оставляет за собой право в одностороннем порядке вносить изменения в данное соглашение без соответствующего уведомления об этом Пользователя, но с обязательной публикацией актуальной версии соглашения на данной странице.</li>
						</ul>
						<li>Предмет соглашения, стоимость оказываемых услуг</li>
						<ul className="__sublist">
							<li>3.1. Предметом настоящего Соглашения являются услуги обмена, покупки и продажи титульных
								знаков электронных платежных систем, осуществляемые по заказу Пользователя, а также
								другие услуги, описанные на веб-сайте Сервиса.
							</li>
							<li>3.2. Размер вознаграждения Сервиса Dexpay за указанные действия отражается в Заявке и
								подтверждается Пользователем на одной из страниц пользовательского интерфейса.
							</li>
							<li>3.3. Стоимость услуг Сервиса Dexpay устанавливается руководством Сервиса и публикуется
								на Интернет-сайте Сервиса.
							</li>
							<li>3.4. Сервис Dexpay вправе самостоятельно изменять курсы обмена Электронных валют и
								взимаемых комиссионных в любое время в одностороннем порядке, о чем уведомляет
								Пользователей Сервиса предварительным размещением информации об этих изменениях на
								Интернет-сайте Сервиса.
							</li>
							<li>3.5. В Заявке, создаваемой Пользователем на Интернет-сайте Сервиса, указывается Курс,
								размер комиссии, взимаемый соответствующей Платежной системой, за проведение Операции,
								размер вознаграждения Сервиса, способ Обмена, а также общая сумма перечисляемых денежных
								средств или электронной валюты.
							</li>
							<li>3.6. Сервис Dexpay взимает стоимость своего вознаграждения в момент проведения соответствующей Операции.</li>
						</ul>
						<li>Условия предоставления услуг</li>
						<ul className="__sublist">
							<li>4.1. Заказ услуг Сервисом Dexpay осуществляется Пользователем путем направления Заявки
								через Интернет-сайт Сервиса.
							</li>
							<li>4.2. Управление процессом обмена или получение информации о ходе выполнения услуги
								Пользователем производятся при помощи соответствующего пользовательского интерфейса,
								расположенного на Интернет-сайте Сервиса.
							</li>
							<li>4.3. Обработка Заявок Пользователя осуществляется Сервисом в строгом соответствии с
								<span onClick={() => linkTo(true)}>политикой конфиденциальности</span>, а также политикой по противодействию отмыванию денег и
								предотвращению операций, носящих незаконный характер (пункт 5 настоящего соглашения).
							</li>
							<li>4.4. Учет операций с электронными единицами производится Сервисом согласно регламенту,
								правилам и формату соответствующих Систем электронных расчетов.
							</li>
							<li>4.5. Любая завершенная операция, проведенная Сервисом по Заявке Пользователя, считается
								неотменяемой, т.е. не может быть отменена Пользователем после ее завершения — получения
								Пользователем причитающегося ему по ранее акцептованным условиям сделки.
							</li>
							<li>4.6. В случае непоступления от Пользователя Цифровых активов (стейлбкоинов USDT) в
								течение о дного часа с момента акцепта Пользователя на реквизиты, предоставленные
								Сервисом, соглашение на условиях, указанных в Заявке, считается расторгнутым Сервисом в
								одностороннем порядке как невступившее в действие, без уведомления об этом Пользователя.
							</li>
							<li>4.7. В случае расторжения соглашения Цифровые активы (стейлбкоины USDT), поступившие
								после указанного выше срока, подлежат возврату на реквизиты отправителя в течение
								последующих 24 часов. При осуществлении возврата все комиссионные расходы на перевод
								Цифровых активов (стейлбкоинов USDT) производятся из поступивших средств за счет
								Пользователя. Сервис не несет ответственность за возможные задержки при возврате, если
								они возникли не по вине Сервиса.
							</li>
							<li>4.8. В случае, если количество поступивших Цифровых активов (стейлбкоинов USDT)
								отличается от заявленного Пользователем, Сервис может в одностороннем порядке
								расторгнуть соглашение путем отказа от исполнения заявки и возврата поступивших средств
								на реквизиты отправителя в течение последующих 24 часов. При осуществлении возврата все
								комиссионные расходы на перевод Цифровых активов (стейлбкоинов USDT) производятся из
								поступивших средств за счет Пользователя. Сервис не несет ответственность за возможные
								задержки при возврате, если они возникли не по вине Сервиса.
							</li>
							<li>4.9. В случае не отправки денежного эквивалента на указанные Пользователем реквизиты в
								течение 24 часов с момента начала действия соглашения, при отсутствии причин для
								блокировки Цифровых активов (стейлбкоинов USDT), поступивших по Заявке Пользователя со
								стороны Сервиса, Пользователь может потребовать расторжения соглашения путем
								аннулирования своей заявки и возврата Цифровых активов (стейлбкоинов USDT) в полном
								объеме.
							</li>
							<li>4.10. Требование об аннулировании заявки должно быть исполнено Сервисом только в том
								случае, если на момент получения такого требования денежный эквивалент не был отправлен
								на реквизиты, указанные Пользователем.
							</li>
							<li>4.11. В случае аннулирования заявки возврат Цифровых активов (стейлбкоинов USDT)
								производится Сервисом на реквизиты отправителя в течение 24 часов с момента получения
								требования об аннулировании. Сервис не несет ответственность за возможные задержки при
								возврате, если они возникли не по вине Сервиса.
							</li>
							<li>4.12. Сервис имеет право привлекать для исполнения своих обязательств сторонних
								исполнителей.
							</li>
							<li>4.13. Сервис имеет право отменить выполняющуюся операцию и вернуть внесенные
								Пользователем Цифровые активы (стейлбкоины USDT) и/или финансовые средства c возмещением
								комиссий Систем электронных расчетов Пользователю без объяснения причин.
							</li>
							<li>4.14. Сервис имеет право отказать Пользователю в дальнейшем обслуживании в случае
								нарушения Пользователем любого из пунктов настоящего соглашения.
							</li>
							<li>4.15. В случае отказа Сервисом от дальнейшего обслуживания Пользователя, Сервис
								уведомляет Пользователя о своем решении при помощи электронной почты или телефонного
								звонка. Далее, Сервис осуществляет возврат Цифровых активов (стейлбкоинов USDT),
								поступивших по Заявке Пользователя на реквизиты отправителя в течение 24 часов с момента
								отказа. При осуществлении возврата все комиссионные расходы на перевод Цифровых активов
								(стейлбкоинов USDT) производятся из поступивших средств за счет Пользователя. Сервис не
								несет ответственность за возможные задержки при возврате, если они возникли не по вине
								Сервиса.
							</li>
							<li>4.16. Все последующие Заявки, созданные Пользователем после отказа Сервисом в дальнейшем
								обслуживании Пользователя, автоматически замораживаются.
							</li>
							<li>4.17. Сервис имеет право задержать выполнение транзакции до проведения идентификации
								личности Пользователя, с помощью звонка по телефону, а также другими способами, не
								противоречащими действующему законодательству.
							</li>
							<li>4.18. При пользовании услугами Сервиса Dexpay, Пользователь подтверждает, что законно
								владеет и распоряжается денежными средствами, участвующими в соответствующем Платеже.
							</li>
							<li>4.19. Пользователь обязуется самостоятельно исчислять и уплачивать все налоги, требуемые в соответствии с налоговым законодательством места нахождения Пользователя.</li>
							<li>4.20. Особые условия некоторых Платежных систем:
								<ul className="__sublist">
									<li>- при отправлении Пользователем денежных средств в платежной системе Яндекс.Деньги, перевод замораживается на 24 часа, по истечению указанного времени перевод размораживается.</li>
									<li>- при отправлении Пользователем денежных средств в платежной системе Bitcoin, заявка на обмен будет принята на исполнение после двух подтверждений в системе Bitcoin.</li>
								</ul>
							</li>
							<li>4.21. Пользователь гарантирует, что он не является вовлеченным в:
								<ul className="__sublist">
									<li>- операции по отмыванию денежных средств;</li>
									<li>- получение доходов от незаконного оборота наркотиков;</li>
									<li>- получение доходов от преступной и/или террористической деятельности;</li>
									<li>- доходов от торговли со странами, торговля с которыми запрещена международными организациями;</li>
									<li>- дохода от любой другой незаконной деятельности.</li>
								</ul>
							</li>
							<li>4.22. Наш сервис предоставляет услуги только совершеннолетним клиентам, поэтому мы не можем осуществлять операции с лицами моложе 18-ти лет.</li>
						</ul>
						<li>Политика противодействия отмыванию денег и незаконным операциям.</li>
						<ul className="__sublist">
							<li>5.1. В соответствии с международным законодательством Dexpay придерживается ряда правил
								и реализует ряд процедур, направленных на предотвращение использования Сервиса с целью
								проведения операций по отмыванию денег, то есть действий, нацеленных на возвращение на
								финансово-экономический рынок денег или иных ценных бумаг незаконного происхождения,
								представляя их приобретение и владение ими вполне законными и нормальными, а также
								проведению иных операций, носящих незаконный характер.
							</li>
							<li>5.2. Для предотвращения операций незаконного характера Сервис устанавливает определенные
								требования ко всем Заявкам, создаваемым Пользователем:
								<ul className="__sublist">
									<li>5.2.1. Отправителем и получателем Платежа по Заявке должно быть одно и то же лицо. С
										использованием услуг Сервиса категорически запрещены переводы в пользу третьих лиц.
									</li>
									<li>5.2.2. Все контактные данные, заносимые Пользователем в Заявку, а также иные
										персональные данные, передаваемые Пользователем Сервису, должны быть актуальными и
										полностью достоверными.
									</li>
									<li>5.2.3. Категорически запрещено создание Заявок Пользователем с использованием анонимных прокси-серверов или каких-либо других анонимных подключений к Интернету.</li>
								</ul>
							</li>

							<li>5.3.Для предотвращения операций незаконного характера, Сервис:
								<ul className="__sublist">
									<li>- Использует внутреннюю систему автоматизированного анализа транзакций и поведения Пользователя (систему предотвращения мошенничества), останавливающую все подозрительные транзакции Пользователя.</li>
									<li>- Устанавливает лимиты на операции Пользователя в зависимости от уровня идентификации личности Пользователя и страны его происхождения.</li>
									<li>- Придерживается политики «Знай своего клиента».</li>
									<li>- Осуществляет проверку всеми доступными способами всех данных, предоставляемых Пользователем.</li>
								</ul>
							</li>
							<li>5.4. Сервис оставляет за собой право заморозить все текущие Заявки Пользователя до момента получения от Пользователя копий документов, удостоверяющих личность Пользователя, а также источник происхождения Цифровых активов (стейлбкоинов USDT) и другую информацию, необходимую для проверки производимой операции в следующих случаях:
								<ul className="__sublist">
									<li>-При выявлении нарушения любого из требований, представленных в пункте 5.2. настоящего Соглашения.</li>
									<li>-При остановке Заявки Пользователя системой предотвращения мошенничества.</li>
									<li>-При возникновении обоснованных подозрений у администрации Сервиса в том, что пользователь пытается воспользоваться услугами Сервиса для отмывания денег или с целью проведения любых других незаконных операций.</li>
								</ul>
								В свою очередь, Пользователь обязуется представить запрашиваемый документ в течение 7 рабочих дней с получения запроса на его предоставление, либо запросить аннулирование заявки.
								<ul className="__sublist">
									<li>5.4.1. В случае отказа Пользователя представить запрашиваемые документы Сервис оставляет за собой право отказать Пользователю в дальнейшем обслуживании и предпринять действия, описанные в п. 4.10 настоящего соглашения.</li>
								</ul>
							</li>

							<li>5.5. Сервис оставляет за собой право отказать Пользователю в дальнейшем обслуживании и предпринять действия, описанные в п. 4.7 настоящего соглашения, после чего передать все имеющиеся у Сервиса данные Пользователя, а также всю имеющуюся информацию об операциях Пользователя в правоохранительные органы в случаях:
								<ul className="__sublist">
									<li>- Выявления транзакций, целью которых является отмывание денег, финансирование террористических организаций, мошенничество любого рода, а также транзакций, направленных на проведение любых других незаконных и неправомерных операций.</li>
									<li>- При возникновении обоснованного подозрения у Сервиса в том, что предоставленный Пользователем документ для идентификации личности Пользователя является поддельным или недействительным.</li>
									<li>- Поступления информации от уполномоченных на то органов о неправомочности владения Пользователем электронными единицами или финансовыми средствами и/или иная информация, делающая невозможным предоставление Сервисом услуг Пользователю.</li>
									<li>- Выявления любых действий или попытки совершения действий Пользователем, направленных на оказание любого негативного влияния на программно-аппаратный комплекс Сервиса.</li>
									<li>- Выявления любых действий или попытки совершения действий Пользователем, направленных на хищение баз данных и другого материального и нематериального имущества Сервиса.</li>
									<li>- Выявления любых действий или попытки совершения действий Пользователем, способных нанести любой физический, материальный и нематериальный вред Сервису.</li>
								</ul>
							</li>
							<li>5.6. Сервис оставляет за собой право проверять всеми доступными ему способами персональную и любую другую информацию, полученную от Пользователя.</li>
						</ul>
						<li>Ограничение ответственности</li>
						<ul className="__sublist">
							<li>6.1. Сервис не является банком. Сервис предлагает свои услуги круглосуточно, без
								выходных и будет стремиться к тому, чтобы программно-аппаратный комплекс, реализующий
								возможности Сервиса, работал без сбоев.
							</li>
							<li>6.2. Сервис предоставляет свои услуги на условиях «как есть», как они описаны на
								страницах сайта Сервиса и не предлагает никаких дополнительных гарантий.
							</li>
							<li>6.3. Используя Сервис, Пользователь соглашается с тем, что объем ответственности Сервиса
								ограничивается средствами, полученными от Пользователя для исполнения предмета
								настоящего соглашения, что Сервис не обеспечивает дополнительных гарантий и не несет
								никакой дополнительной ответственности перед Пользователем, также как и Пользователь не
								несет дополнительной ответственности перед Сервисом, за исключением случаев, указанных в
								п. 7.9.
							</li>
							<li>6.4. Сервис приложит все усилия, но не гарантирует, что его услуги будут доступны
								круглосуточно, ежедневно. Сервис не несет никакой ответственности за убытки,
								неполученную прибыль и иные издержки Пользователя, возникшие в результате невозможности
								получения доступа к сайту и услугам Сервиса.
							</li>
							<li>6.5. Сервис не несет никакой ответственности за убытки, неполученную прибыль и иные
								издержки Пользователя, явившиеся результатом задержек, ошибок или сбоев в проведении
								банковских платежей или электронных переводов.
							</li>
							<li>6.6. Сервис не несет никакой ответственности за убытки, неполученную прибыль и иные
								издержки Пользователя, явившиеся результатом ошибочных ожиданий Пользователя в отношении
								тарифных ставок Сервиса, прибыльности транзакций и иных субъективных факторов.
							</li>
							<li>6.7. В случае предоставления Пользователем ошибочных данных в информации о реквизитах получателя денежных средств Сервис не несет никакой ответственности за любые неблагоприятные последствия или ущерб, возникшие в результате этой ошибки.</li>
							<li>6.8. Пользуясь услугами Сервиса, Пользователь самостоятельно несет ответственность за выплату налогов в соответствии с налоговым законодательством страны по месту его проживания. Сервис не является налоговым агентом, и не будет уведомлять Пользователя о каких-либо возможных налоговых издержках при использовании услуг Сервиса.</li>
							<li>6.9. Пользователь гарантирует возмещение убытков Сервиса (управляющей компании, руководителей и сотрудников) в случаях предъявления исков или претензий, прямо или косвенно связанных с использованием Пользователем услуг Сервиса, за исключением убытков, вызванных виновными (умышленными или неосторожными) действиями самого Сервиса.</li>
							<li>6.10. Пользователь обязуется воздержаться от использования услуг Сервиса для проведения мошеннических и незаконных операций и соглашается с тем, что любая попытка обмена мошеннического капитала будет иметь судебное преследование по всей строгости закона. Действия Пользователя могут считаться незаконными в соответствии с законодательством страны пребывания Пользователя и/или страны, где зарегистрирован Сервис.</li>
							<li>6.11. Пользователь обязуется не фальсифицировать коммуникационные потоки, связанные с функционированием Сервиса, не вмешиваться в его программную и/или аппаратную часть, а также не оказывать какого-либо иного влияния, способного нарушить нормальную работу Сервиса, понимая, что подобные действия будут иметь судебное преследование по всей строгости закона.</li>
							<li>6.12. В случае обнаружения фальсификации коммуникационных потоков или оказания какого-либо негативного влияния на нормальную работу программного кода Сервиса, имеющего прямое или косвенное отношение к заявке Пользователя, исполнение заявки Сервисом приостанавливается, после чего предпринимаются действия, описанные в п. 6.5 настоящего соглашения.</li>
							<li>6.13. Ни Пользователь, ни Сервис не будут ответственны друг перед другом за задержки или неисполнение своих обязательств, являющихся результатом возникновения обстоятельств непреодолимой силы, включая стихийные бедствия, пожар, наводнение, террористические акты, смены власти, гражданские волнения, а также не функционирования Электронных расчетных систем, систем энергоснабжения, сетей связи и поставщиков Интернет-услуг.</li>
							<li>6.14. Электронные расчетные системы и/или финансовые учреждения несут исключительную ответственность за средства, вверенные им Пользователем. Сервис не может являться стороной в соглашении между Платежной системой и/или финансовым учреждением и Пользователем, и ни в какой мере не несет ответственности за неправильное или неправомочное использование возможностей Электронной расчетной системы Пользователем, а также за злоупотребление Пользователем функциональностью Электронной расчетной системы. Взаимные права и обязанности Пользователя и Электронной расчетной системы и/или финансового учреждения регулируются соответствующими договорами.</li>
						</ul>
						<li>Порядок принятия претензий и разрешения споров</li>
						<ul className="__sublist">
							<li>7.1.	Споры и разногласия, возникшие в рамках предоставления услуг Сервисом Пользователю, разрешаются путем переговоров Пользователя с администрацией Сервиса, на основе положений настоящего соглашения.
								<ul className="__sublist">
									<li>7.1.1.	Любые претензии по настоящему соглашению могут быть приняты Сервисом в электронном виде путем отправки сообщения по сути претензии на реквизиты (<span onClick={() => linkTo(false)}>контакты</span>), указанные на сайте Сервиса.</li>
								</ul>
							</li>
							<li>7.2. В случае невозможности разрешения возникающих вопросов путем переговоров, спор разрешается в соответствии с действующим законодательством по месту регистрации Сервиса.</li>
						</ul>
						<li>Заключительные положения</li>
						<ul className="__sublist">
							<li>8.1. Сервис Dexpay вправе отправлять Пользователю на указанный им e-mail информацию о
								состоянии процесса обмена, а также иную информацию, в том числе рекламного характера.
							</li>
							<li>8.2. Информация на сайте, включая графические изображения, текстовую информацию,
								программные коды и т.п. является собственностью сайта Dexpay и охраняется законами об
								авторском праве. Каждый случай несанкционированного копирования (полного или частичного)
								может преследоваться в рамках действующего законодательства.
							</li>
							<li>8.3. Акцепт настоящей оферты означает безусловное согласие Плательщика на обработку
								Сервисом его персональных данных, предоставляемых Плательщиком Сервисом в целях
								предоставления услуг, в целях направления Плательщику сообщений информационного и
								рекламного характера. Акцепт оферты означает согласие Плательщика на совершение Сервисом
								операций по сбору, систематизации, накапливанию, хранению, уточнению (обновлению,
								изменению), использованию, распространению (в том числе передаче третьим лицам),
								обезличиванию, блокированию и уничтожению в отношении его персональных данных, на
								использование при обработке персональных данных автоматизированного, механического,
								ручного и любого иного способа по усмотрению Сервиса. Акцепт настоящей оферты также
								подтверждает безусловное согласие Плательщика с тем, что полнота, достоверность и
								точность информации, предоставленной Плательщиком, может быть проверена Сервисом, а
								также представлена Сервисом третьим лицам для целей такой проверки.
							</li>
							<li>8.4. Данное согласие может быть в любой момент отозвано Плательщиком путем
								предоставления Сервису заявления об отзыве согласия на обработку его персональных
								данных. Настоящее согласие на обработку персональных данных предоставлено на срок, до
								отзыва Плательщиком согласия на обработку персональных данных.
							</li>
							<li>8.5. Пользователь подтверждает, что он ознакомился со всеми положениями настоящего Соглашения и, безусловно принимает их, в противном случае Пользователь не может использовать Сервис Dexpay.</li>
						</ul>
					</ul>
				</div>
			</div>
		</div>
	);
}

export default Rules;
