import React, {useContext, useState} from 'react';
import "./LoginForm.scss";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import eyeShow from "../../img/eyeShow.svg";
import eyeHide from "../../img/eyeHide.svg";
import {useNavigate} from "react-router-dom";

function LoginForm(props) {
	const {store} = useContext(Context);
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("")
	const [isVisiblePassword, setIsVisiblePassword] = useState(false);
	const [isVisibleConfirmPassword, setIsVisibleConfirmPassword] = useState(false);
	const [identicalError, setIdenticalError] = useState(false);

	const togglePasswordVisibility = () => {
		setIsVisiblePassword(!isVisiblePassword);
	};
	const toggleConfirmPasswordVisibility = () => {
		setIsVisibleConfirmPassword(!isVisibleConfirmPassword);
	};

	const navigateToLogin = () => {
		navigate("/login");
		window.scrollTo(0,0);
	}

	const navigateToRegistration = () => {
		navigate("/registration");
		window.scrollTo(0,0);
	}


	const emailHandler = (e) =>{
		e.preventDefault();
		setEmail(e.target.value)
	}

	const passwordHandler = (e) =>{
		e.preventDefault();
		setPassword(e.target.value)
	}

	const passwordConfirmHandler = (e) =>{
		e.preventDefault();
		setConfirmPassword(e.target.value)
	}

	const handleLogin = async () => {
		if (!props.isPrivacyAgreed) {
			props.setPrivacyPolicyValid(false);
			return;
		}
		try {
			await store.login(email, password);
			if (!Object.keys(store.errors).length) {
				navigate('/profile');
			}
		} catch (error) {
			console.error('Ошибка при входе:', error);
		}
	};

	const handleRegistration = async () => {
		if (!props.isPrivacyAgreed) {
			props.setPrivacyPolicyValid(false);
			return;
		}
		if(password !== confirmPassword){
			setIdenticalError(true);
			return;
		}else{
			setIdenticalError(false)
		}
		try {
			await store.registration(email, password);
			if (!Object.keys(store.errors).length) {
				props.setConfirmEmail(true);
			}
		} catch (error) {
			console.error('Ошибка при регистрации:', error);
		}
	};
	return (
		<div className="__input_group">
			<input
				onChange={(e) => emailHandler(e)}
				value={email}
				type="text"
				className={store.errors.email ? 'invalid-input' : ''}
				placeholder="Email"
				autoComplete="email"
			/>
			{store.errors.email && <div className="error-message">{store.errors.email}</div>}
			<div className="password-container">
				<input
					type={isVisiblePassword ? 'text' : 'password'}
					value={password}
					onChange={(e) => passwordHandler(e)}
					placeholder="Пароль"
					className={store.errors.password || identicalError ? 'invalid-input' : ''}
					autoComplete="current-password"
				/>
				{isVisiblePassword ? (
					<img onClick={togglePasswordVisibility} src={eyeShow} alt="img" />
				) : (
					<img onClick={togglePasswordVisibility} src={eyeHide} alt="img" />
				)}
			</div>
			{
				props.type === "reg" &&
				<div className="password-container">
					<input
						type={isVisibleConfirmPassword ? 'text' : 'password'}
						value={confirmPassword}
						onChange={(e) => passwordConfirmHandler(e)}
						placeholder="Повторите пароль"
						className={store.errors.password || identicalError ? 'invalid-input' : ''}
						autoComplete="current-password"
					/>
					{isVisibleConfirmPassword ? (
						<img onClick={toggleConfirmPasswordVisibility} src={eyeShow} alt="img" />
					) : (
						<img onClick={toggleConfirmPasswordVisibility} src={eyeHide} alt="img" />
					)}
				</div>
			}

			{identicalError && <div className="error-message">Пароли не совпадают</div>}
			{store.errors.password && <div className="error-message">{store.errors.password}</div>}
			{store.errors.general && <div className="error-message">{store.errors.general}</div>}
			{
				props.type === "login" ?
					<button className="__btn" onClick={handleLogin}>Войти</button> :
					<button className="__btn" onClick={handleRegistration}>Регистрация</button>
			}
			{props.type === 'login' ? (
					<div className="login_btns">
						<button className="recover__btn" onClick={() => props.handleForgetPassword()}>Забыли пароль?</button>
						<div className="register-btn">
							<p>Нет аккаунта? <button onClick={navigateToRegistration}>Зарегистрироваться</button></p>
						</div>
					</div>

			) : (
				<p className="__direct">
					Есть аккаунт? <button onClick={navigateToLogin}>Войти</button>
				</p>
			)}
		</div>
	);
}

export default observer(LoginForm);