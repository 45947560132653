import React from 'react';
import "./ProfileReferral.scss";

function ProfileReferral(props) {
	return (
		<div>
			referral
		</div>
	);
}

export default ProfileReferral;