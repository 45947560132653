import React, {useState, useEffect} from 'react';
import "./Exchanger.scss";
import axios from "axios";
import tether from "../../img/tether.svg";
import rub from "../../img/rubIcon.svg";
import swap from "../../img/swapImg.svg";

function Exchanger() {
	const [selectedFirstInput, setSelectedFirstInput] = useState({
		transcript: "RUB",
		img: rub,
	});
	const [selectedSecondInput, setSelectedSecondInput] = useState({
		transcript: "USDT",
		img: tether,
	});
	const [firstInput, setFirstInput] = useState("");
	const [secondInput, setSecondInput] = useState("");
	const [isValidFirstInput, setIsValidFirstInput] = useState(false);
	const [isValidSecondInput, setIsValidSecondInput] = useState(false);
	const [rate, setRate] = useState("");


	useEffect(() => {
		fetchExchangeRate();
		const interval = setInterval(() => {
			const now = new Date();
			const hours = now.getHours();
			const minutes = now.getMinutes();
			const currentTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
			if (currentTime === '11:30') {
				fetchExchangeRate();
			}
		}, 60000);
		return () => clearInterval(interval);
	}, []);

	const fetchExchangeRate = async () => {
		try {
			const response = await axios.get('https://www.cbr-xml-daily.ru/daily_json.js');
			const usdRate = response.data.Valute.USD.Value;
			setRate(usdRate.toFixed(2));
		} catch (error) {
			console.error('Error fetching exchange rate:', error);
		}
	};

	const handleSwap = () => {
		// const tempFirstInput = { ...selectedFirstInput };
		// setSelectedFirstInput({ ...selectedSecondInput });
		// setSelectedSecondInput(tempFirstInput);
		// setFirstInput("");
		// setSecondInput("");
	}

	const handleInputChange = (event, isFirstInput) => {
		let inputValue = event.target.value;

		if(isFirstInput){
			setFirstInput(inputValue);
			setSecondInput((parseFloat(inputValue) / parseFloat(rate)).toFixed(2));
		}
		else{
			setSecondInput(inputValue)
			setFirstInput((parseFloat(inputValue) * parseFloat(rate)).toFixed(2));
		}
	}

	const handleSubmit = () => {
		firstInput === "" ? setIsValidFirstInput(true) : setIsValidFirstInput(false);
		secondInput === "" ? setIsValidSecondInput(true) : setIsValidSecondInput(false);
		const data = {
			receivedNum: firstInput,
			receivedCurrency: selectedFirstInput.transcript,
			giveNum: secondInput,
			giveCurrency: selectedSecondInput.transcript,
			rate: rate,
		}
		console.log(data)
	}

	return (
		<div className="exchanger">
			<h2>Купить {selectedFirstInput.transcript === "RUB" ? "USDT" : "RUB"}</h2>
			<div className="__input_wrapper">
				<div className="__item">
					<div className={isValidFirstInput ? "__content_wrapper invalid" : "__content_wrapper"}>
						<div className="__input">
							<div className="__text">
								<p>Вы отдадите: </p>
							</div>
							<input type="text"
							       value={/^[0-9.]*$/.test(firstInput) ? firstInput : ""}
							       onChange={(event) => handleInputChange(event, true)}
							       onFocus={(event) => event.target.select()}
							       placeholder="0.00"
							/>
						</div>
						<div className="__currency">
							<img src={selectedFirstInput.img} alt="img"/>
							<span>{selectedFirstInput.transcript}</span>
						</div>
					</div>
				</div>
				<div className="__swap">
					<img onClick={handleSwap} src={swap} alt="img"/>
				</div>
				<div className="__item">
					<div className={isValidSecondInput ? "__content_wrapper invalid" : "__content_wrapper"}>
						<div className="__input">
							<div className="__text">
								<p>Вы получите: </p>
							</div>
							<input type="text"
							       value={/^[0-9.]*$/.test(secondInput) ? secondInput : ""}
							       onChange={(event) => handleInputChange(event, false)}
							       onFocus={(event) => event.target.select()}
							       placeholder="0.00"
							/>
						</div>
						<div className="__currency">
							<img src={selectedSecondInput.img} alt="img"/>
							<span>{selectedSecondInput.transcript}</span>
						</div>
					</div>
				</div>
			</div>
			<div className="__purchase">
				<div className="__rate">
					<p>1 USDT = {rate} RUB</p>
				</div>
				<button onClick={handleSubmit}>Купить</button>
			</div>
		</div>
	);
}

export default Exchanger;