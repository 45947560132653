import React, { useState, useEffect } from 'react';
import './ReviewList.scss';
import quotes from "../../img/quotes.svg";
import {useNavigate} from "react-router-dom";

const ReviewList = ({ reviews }) => {
	const navigate = useNavigate();
	const [itemsPerPage, setItemsPerPage] = useState(3);

	useEffect(() => {
		const handleResize = () => {
			const width = window.innerWidth;
			if (width >= 1024) {
				setItemsPerPage(3);
			} else if (width >= 767) {
				setItemsPerPage(2);
			} else {
				setItemsPerPage(1);
			}
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);


	const visibleReviews = reviews.slice(0, itemsPerPage);

	const linkToReviewForm = () => {
		navigate("/reviewForm");
		window.scrollTo(0,0);
	}

	return (
		<div className="review-list">
			<div className="container">
				<h2>Отзывы</h2>
				<div className="__body">
					{visibleReviews.map((review, index) => (
						<div key={index} className="__item">
							<div className="__content">
								<img src={quotes} alt="img"/>
								<p>{review.review}</p>
							</div>
							<h3>{review.name}</h3>
						</div>
					))}
				</div>
				<div className="addBtn">
					<button onClick={linkToReviewForm}>Оставить отзыв</button>
				</div>
			</div>
		</div>
	);
};

export default ReviewList;